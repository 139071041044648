.footer {
  text-align: center;
}

.content {
  padding: 20px 50px;
}


@media only screen and (max-width: 600px) {
  .content {
    padding: 20px 20px;
  }
}