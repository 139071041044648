.appbar-title {
  color: white;
  font-size: 32px;
  font-weight: 500;
}

.appbar-right-menu {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.appbar-right-menu-item {
  color: white;
  font-size: 14px;
}